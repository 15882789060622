body {
    font-family: "Mulish", sans-serif!important;
  }
  
 .main-container-bg{
  background-color: #F6F7FB;
  overflow: hidden;
 } 

 .full{
  height: 100vh;
 }

/* .style-8::-webkit-scrollbar-track
 {
   border: 1px solid #d3bbe2;

   background-color: #F5F5F5;
 }
 
.style-8::-webkit-scrollbar
{
	width: 10px;
	background-color: #F6F7FB;
}

.style-8::-webkit-scrollbar-thumb
{
	background-color: #e1d2eb;	
  border-left: 1px solid #d3bbe2 ;
  border-right: 1px solid #d3bbe2 ;
} */


:root {
  --primary-active-color: rgb(171, 45, 212);
  --prime-background:#e6edf5;
}

.dark{
  --primary-active-color: #e6edf5;
  --prime-background:#222133;
}

.red{
  color: #E062B5;
}


.font-size-label{
font-size: 0.8em;
color: #aaa;
}

.active {
  color: var(--primary-active-color);
  background-color: var(--prime-background);
  border-bottom: 2px solid #d7bde7; 
  /* background-color: #8AC0EB;
  background: linear-gradient(90deg, #8AC0EB 0%, #A876E8 100%); */
}

.ps-menu-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: normal !important;

}

.css-uf9z8p {
  font-size: 0.75rem !important;
}

.ps-menu-icon{
  color: #82A1B0 !important;
  margin-right: 0 !important;
}
.css-1qyjfg5{
  height: auto!important;
}
/* .css-10li5rz {
  border-color: #0b2948!important;
  border-right-width: 1px;
  border-right-style: none !important;;
  border-color: none !important;;
} */
.ps-sidebar-root {
  position: relative;
  border-right-width: 1px;
  border-right: none !important;
  border-color: none !important;
}
.ps-collapsed {
  width: 75px !important;
  min-width: 75px !important;
}
.active span{
  font-weight: 600;
}
.active .css-11alqgo{
  font-weight: 600;
  color: #7839CD !important;
}

.menu-scroll{
  height:92vh;
  overflow-y:auto;
  overflow: scroll;
  -ms-overflow-style: none;  
  scrollbar-width: none;
  background-color: #10101a;
}

.ps-sidebar-container {
  min-height: 100vh;
  height: auto !important; 
}

.css-1vnocy3{
  min-height: 100vh;
  height: auto !important;
}

.font-sidebar-btn{
font-size: 12px!important;
}


/* .fixed-user-logo{
  position:fixed;
  width: 200px !important;
} */

.font-theme{
  font-size: 1em !important;
  background-color: transparent!important;
  border: none!important;
  box-shadow: none!important;
}

.font-theme:hover,
.font-theme:focus,
.font-theme:active {
  background-color: transparent!important;
  box-shadow: none!important;
  border: none!important;
}




.theme-ur{
  font-size: 10px;
}