.custom-container {
  --bs-gutter-x: 0;
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  color: #333333;
  background: #dddddd;
  border: none;
  outline: none;
  border-radius: 50%;
}

.icon-button:hover {
  cursor: pointer;
}

.icon-button:active {
  background: #cccccc;
}

.icon-button__badge {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 15px;
  height: 15px;
  background: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
}

.newSize {
  font-size: 0.8em;
  color: #6f42c1;
}

.btn-custom {
  background-color: #67349D !important;
}

/* .modal-90w {
  min-width: 90vw !important;
} */

.subscription-item {
  margin-bottom: 10px;
}

.heart-icon {
  margin-right: 10px;
}

.plan-details {
  display: flex;
  flex-direction: column;
}

.plan-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.plan-prices {
  display: flex;
  flex-direction: column;
}

.plan-prices div {
  margin-bottom: 5px;
}

.file-input-container {
  flex: 1;
}

img.ms-3 {
  margin-left: 1rem;
  /* Adjust the margin as needed */
}
